import axios from "axios";

export default class DbManager {
  static mURL = "/api";

  static async POST(url, body) {
    try {
      const response = await axios.post(this.mURL + url, body);
      let data = response.data;

      if (response.status === 201) {
        return response.data;
      } else {
        throw new Error(`Error: ${data.status} - ${data.statusText}`);
      }
    } catch (error) {
      console.error(`HTTP error!`, error.response || error);
      throw error;
    }
  }

  static async GET(url, parmas) {
    try {
      const response = await axios.get(this.mURL + url, {
        parmas: parmas,
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error("Failed to load data:", error.response || error);
      throw error;
    }
  }
}
