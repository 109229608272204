export const widgetContainer = {
  padding: "10px",
  boxShadow: "2px 4px 10px 1px rgba(201, 201, 201, 0.47)",
  borderRadius: "10px",
};

export const widgetTextSection = {
  padding: "2em 0 1em 1.5em",
};

export const widgetChartSection = {
  padding: "1em 1.5em 1em 1em",
  width: "140px",
  height: "140px",
};
