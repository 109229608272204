import { useState, useEffect } from "react";
import DbManager from "globalApi/DbManager";

const useTotalAchieve = () => {
  const [data, setData] = useState({ value: null, total: null, goal: null });

  useEffect(() => {
    const fetchData = async () => {
      const resp = await DbManager.POST("/achieve/data", {
        date: "2024-03-12",
      });
      setData({
        value: resp[0].ach_value,
        total: resp[0].ach_total,
        goal: resp[0].ach_goal,
      });
    };
    fetchData();
  }, []);
  return data;
};

export default useTotalAchieve;
