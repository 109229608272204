import { Chart } from "react-google-charts";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useRef } from "react";
import { ExportPDF } from "utils/ExportPDF";
import { Box, Typography } from "@mui/material";
import { fontBold } from "styles/style";
import { expandedChart } from "./ChartDisplayStyle";

const ReformatData = (data) => {
  const keys = Object.keys(data);
  const header = keys;
  const result = [header];
  const numRows = data[keys[0]].length;

  for (let i = 0; i < numRows; i++) {
    const row = [];

    keys.forEach((key) => {
      let value = data[key][i];
      if (key !== "store") {
        value = value / 100;
      }
      row.push(value);
    });
    result.push(row);
  }

  return result;
};

const ChartDisplay = (props) => {
  const reformattedData = ReformatData(props.data);
  const reformattedProps = {
    ...props,
    data: reformattedData,
  };
  return <ExpandedChart param={reformattedProps} />;
};

const ExpandedChart = ({ param }) => {
  const createPDF = useRef(null);
  const saveAsPDF = () => {
    if (createPDF.current) {
      ExportPDF(createPDF.current);
    }
  };

  return (
    <Box
      sx={expandedChart}
      style={{
        boxShadow: param.color.boxShadow,
      }}
    >
      <FileDownloadOutlinedIcon
        onClick={saveAsPDF}
        sx={{ cursor: "pointer" }}
      />

      <Typography sx={fontBold}>Sales Achievement by Stores</Typography>
      <Box className="chartSection">
        <Chart chartType={param.chartType} data={param.data} />
      </Box>
    </Box>
  );
};

export default ChartDisplay;
