export const miniNaviHeader = {
  height: "60px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "black",
};

export const miniNaviExtendIcon = {
  marginRight: "5px",
  color: "white",
  cursor: "pointer",
};

export const miniNaviDrawer = {
  backgroundColor: "black",
  color: "white",
};
