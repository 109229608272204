export const loginLabel = {
  width: "300px",
  height: "121px",
  backgroundColor: "blue",
  color: "white",
  fontSize: "30px",
  borderRadius: "15px",
  background: "linear-gradient(195deg, #49a3f1, #1a73e8)",
};

export const loginForm = {
  height: "170px",
  flexDirection: "column",
  gap: 3,
  paddingTop: "80px",
  borderColor: "rgb(255, 0, 0)",
};

export const loginButton = {
  padding: "10px",
  width: "290px",
  color: "white",
  cursor: "pointer",
  background: "linear-gradient(195deg, #49a3f1, #1a73e8)",
  borderRadius: "15px",
};

export const userInput = {
  width: "280px",
  borderRadius: "12px",
};
