import React, { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Sidebar from "layouts/sidenavbar/SideNavbar";
import MiniMizedNav from "layouts/sidenavbar/minimizedNav/MiniMizedNav";
import Table from "layouts/table/TableDisplay";
import Login from "layouts/login/Login";
import DetailByStores from "layouts/detailbystores/DetailByStores";
import VendorReport from "layouts/table/VendorReport";
import { Box } from "@mui/material";
import { mainContainer } from "styles/style";
import { branchList } from "utils/BranchList";
import MiniNav from "layouts/sidenavbar/minimizedNav/MiniNav";

import OrderPage from "layouts/order/OrderPage";

function Authenticated() {
  const isBigScreen = useMediaQuery({ query: "(min-width: 600px)" });
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  return (
    <Box sx={{ display: { xs: "block", sm: "flex" }, padding: { sm: "10px" } }}>
      {location.pathname !== "/login" &&
        (isBigScreen ? (
          <Sidebar />
        ) : (
          //new version
          <MiniNav />
          //old version
          // <MiniMizedNav menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        ))}
      <Box sx={mainContainer}>
        <Routes>
          <Route path="/" element={<DetailByStores />} />
          <Route path="/about" element={<Table />} />
          <Route path="/dashboard" element={<DetailByStores />} />
          <Route path="/login" element={<Login />} />
          <Route path="/vendors/:id/order" element={<OrderPage />} />
          <Route
            path="/vendors/:id"
            element={<VendorReport vendor={location.pathname.split("/")[2]} />}
          />
          {Object.entries(branchList).map(([key, value], index) => (
            <Route
              key={key + index}
              path={`/stores/${key}`}
              element={<DetailByStores branch={value} />}
            />
          ))}
        </Routes>
      </Box>
    </Box>
  );
}

export default Authenticated;
