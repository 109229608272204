import React, { useEffect, useState } from "react";
import { Box, Button, TextField } from "@mui/material";

const NumberCounter = ({
  currentNum,
  setCurrentOrderTable,
  index,
  currentOrderTable,
}) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    const setInitalCount = () => {
      setCount(currentNum);
    };
    setInitalCount();
  }, [currentNum]);

  const handleIncrement = () => {
    setCount(count + 1);
    setCurrentOrderTable((prevTable) => {
      const newTable = [...prevTable];
      newTable[index] = {
        ...newTable[index],
        Quantity: newTable[index].Quantity + 1,
      };
      return newTable;
    });
  };

  const handleDecrement = () => {
    if (count !== 0) {
      setCount(count - 1);
      setCurrentOrderTable((prevTable) => {
        const newTable = [...prevTable];
        newTable[index] = {
          ...newTable[index],
          Quantity: newTable[index].Quantity - 1,
        };
        return newTable;
      });
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;

    if (!isNaN(value)) {
      setCount(value);
      console.log(count);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setCount(count);
      setCurrentOrderTable((prevTable) => {
        const newTable = [...prevTable];
        newTable[index] = {
          ...newTable[index],
          Quantity: count,
        };
        return newTable;
      });
    }
  };
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <Button variant="contained" onClick={handleDecrement}>
        -
      </Button>
      <TextField
        type="number"
        value={count}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        inputProps={{ min: 0 }}
        sx={{ width: "60px", textAlign: "center" }}
      />
      <Button variant="contained" onClick={handleIncrement}>
        +
      </Button>
    </Box>
  );
};

export default NumberCounter;
