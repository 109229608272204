import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Box, Typography } from "@mui/material";
import { flexColunmAround, fontBold } from "styles/style";
import {
  widgetChartSection,
  widgetContainer,
  widgetTextSection,
} from "./WidgetStyle";
import { redFont } from "styles/style";
import { flexSpaceNoAlignSpaceBetween } from "styles/style";

const Widget = ({ data }) => {
  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(value);
  };

  return (
    <Box sx={widgetContainer}>
      <Box sx={flexSpaceNoAlignSpaceBetween}>
        <Typography sx={fontBold}>{data.title}</Typography>
      </Box>
      <Box sx={flexSpaceNoAlignSpaceBetween}>
        <Box sx={{ ...flexColunmAround, ...widgetTextSection }}>
          <Typography sx={fontBold}>{formatCurrency(data.goal)}</Typography>
          <Typography sx={{ ...fontBold, ...redFont }}>
            {formatCurrency(data.total)}
          </Typography>
        </Box>
        <Box sx={{ ...flexColunmAround, ...widgetChartSection, ...fontBold }}>
          <CircularProgressbar
            value={data.value}
            text={`${data.value}%`}
            styles={buildStyles({
              textColor: data.color,
              pathColor: data.color,
              trailColor: "#edeef0",
            })}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Widget;
