export const barChartProps = {
  chartType: "Bar",
  width: "100%",
  height: "100%",
  color: {
    background: "#49a3f1",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
  },
};

export const achieveDefault = {
  title: "Total Sales Achievement",
  color: "#49a3f1",
};

export const marginDefault = {
  title: "Total Sales Margin",
  color: "#66BB6A",
};
