import { TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

export const vendorReportTitleInfoStyles = {
  display: "flex",
  justifyContent: "space-between",
  gap: 3,
  flexDirection: "column",
};

export const vendorReportHeader = {
  display: "flex",
  fontWeight: "bold",
  justifyContent: "flex-end",
  fontSize: "14px",
  marginRight: "80px",
  gap: 5,
  marginBottom: "30px",
  marginTop: "30px",
};

export const venderTableTitle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  fontSize: "20px",
  marginBottom: "10px",
};

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#49a3f1",
    color: theme.palette.common.white,
    fontWeight: "bold",
    padding: theme.spacing(1),
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: theme.spacing(1),
  },
}));

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== "isClickable",
})(({ theme, isClickable }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  cursor: isClickable ? "pointer" : "default",
  "&:hover": {
    backgroundColor: isClickable ? theme.palette.action.selected : "",
  },
}));

export const tableBoxShawdow = {
  boxShadow: "2px 4px 10px 1px rgba(0, 0, 0, 0.24)",
};
