import DbManager from "globalApi/DbManager";
import React from "react";

const EmailSender = async ({ createdExcel }) => {
  console.log(createdExcel.get("file"));

  const response = await DbManager.POST("/email/send-email", createdExcel);

  if (response.ok) {
    console.log("Email sent successfully!");
  } else {
    console.log("failed to send email");
  }

  return <div></div>;
};

export default EmailSender;
