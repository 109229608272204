import DbManager from "globalApi/DbManager";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, TextField } from "@mui/material";
import { placeAtTheCenter } from "styles/style";
import { loginForm, loginLabel, loginButton, userInput } from "./LoginStyle";
import { flexCenterStyles } from "styles/style";
const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleIputChange = (event) => {
    const { name, value } = event.target;

    if (name === "username") {
      setUsername(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    const result = await DbManager.POST("/employee/userInfo", {
      epNo: username,
      password: password,
    });
    if (result) {
      navigate(`/vendors/${result[0].c_name.toLowerCase()}`);
    } else {
      alert("Please check your username");
    }
  };

  return (
    <Box
      sx={{
        ...placeAtTheCenter,
      }}
    >
      <Box sx={{ ...flexCenterStyles, ...loginLabel }}>Sign In</Box>
      <Box sx={{ ...flexCenterStyles, ...loginForm }}>
        <TextField
          InputProps={{ sx: userInput }}
          type="text"
          name="username"
          placeholder="Username"
          value={username}
          onChange={handleIputChange}
        />
        <TextField
          InputProps={{ sx: userInput }}
          type="password"
          name="password"
          placeholder="Password"
          value={password}
          onChange={handleIputChange}
        />
        <Button
          onClick={handleLogin}
          sx={{ ...flexCenterStyles, ...loginButton }}
        >
          LOGIN
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
