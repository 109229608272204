export const sidebar = {
  display: "flex",
  flexDirection: "column",
  position: "sticky",
  minWidth: "250px",
  paddingTop: "35px",
  transition: "all 300ms ease",
  background: "linear-gradient(195deg, #42424a, #191919)",
  color: "#fff",
  borderRadius: "0.75rem",
  height: "86vh",
};

export const logoImage = {
  width: "50px",
  height: "50px",
  marginRight: "15px",
};

export const sideMenuContainder = {
  marginTop: "35px",
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  padding: "10px",
};

export const sideMenuItems = {
  default: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    position: "relative",
    transition: "all 300ms ease",
    fontSize: "15px",
    padding: "12px 10px 12px 10px",
    margin: "0 5px 0 5px",
    borderRadius: "0.375rem",
    "&:hover": {
      cursor: "pointer",
      background: "#767676",
    },
  },
  active: {
    background: "linear-gradient(195deg, #49a3f1, #1a73e8)",
  },
};
