import React, { useState, Fragment } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Logo from "../../../img/logo.png";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { branchList } from "utils/BranchList";
import { minivaviImage } from "styles/style";
import {
  miniNaviDrawer,
  miniNaviExtendIcon,
  miniNaviHeader,
} from "./MiniNavStyle";

const MiniNav = () => {
  const [drawerState, setDrawerState] = useState({
    top: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      sx={miniNaviDrawer}
    >
      <List>
        {Object.keys(branchList).map((text, index) => (
          <ListItem key={text + index} disablePadding>
            <ListItemButton>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box>
      <Fragment key={"top"}>
        <Box sx={miniNaviHeader}>
          <Box component="img" src={Logo} alt="Logo" sx={minivaviImage} />
          <MenuOutlinedIcon
            onClick={toggleDrawer("top", true)}
            sx={miniNaviExtendIcon}
          />
        </Box>
        <SwipeableDrawer
          anchor={"top"}
          open={drawerState["top"]}
          onClose={toggleDrawer("top", false)}
          onOpen={toggleDrawer("top", true)}
        >
          {list("top")}
        </SwipeableDrawer>
      </Fragment>
    </Box>
  );
};

export default MiniNav;
