import React, { useState } from "react";
import Logo from "../../img/logo.png";
import { SidebNavlist } from "./sidenavlist";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { PAGES } from "globalApi/EnumType";
import { Box, Typography } from "@mui/material";
import {
  logoImage,
  sidebar,
  sideMenuContainder,
  sideMenuItems,
} from "./SidenavBarStyle";
import { flexCenterStyles } from "styles/style";
import { fontBold } from "styles/style";

const SideNavbar = () => {
  const [selected, setSelected] = useState(0);

  const navigate = useNavigate();

  const movePage = async (index) => {
    setSelected(index);

    switch (index) {
      case PAGES.DASHBOARD:
        navigate("/");
        break;
      case PAGES.PROFILE:
        break;
      case PAGES.LOGIN:
        navigate("/login");
        break;
      default:
        navigate("/about");
    }
  };

  return (
    <Box sx={sidebar}>
      <Box sx={flexCenterStyles}>
        <Box component="img" src={Logo} alt="logo" sx={logoImage} />
        <Typography variant="body1" sx={fontBold}>
          HMART
        </Typography>
      </Box>
      <Box sx={sideMenuContainder}>
        {SidebNavlist.map((item, index) => {
          return (
            <Box
              sx={{
                ...sideMenuItems.default,
                ...(selected === index ? sideMenuItems.active : {}),
              }}
              key={item + index}
              onClick={() => movePage(index)}
            >
              <item.icon />
              <Typography>{item.heading}</Typography>
            </Box>
          );
        })}
        <Box sx={sideMenuItems.default}>
          <LogoutIcon />
          <Typography>Sign out</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SideNavbar;
