export const PAGES = {
  DASHBOARD: 0,
  PROFILE: 1,
  LOGIN: 2,
};

export const STORE = {
  Coquitlam: "61",
  Downtown: "62",
  Langley: "63",
  Richmond: "64",
  CQcenter: "65",
  POCO: "66",
  Dunbar: "67",
  UBC: "68",
};
