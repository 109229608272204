import DbManager from "globalApi/DbManager";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { Box, Stack } from "@mui/material";
import SelectButton from "buttons/SelectButton";
import DateSelector from "buttons/DateSelector";
import VendorReportTable from "./VendorReportTable";
import {
  accumulateByItems,
  cacluateTotals,
  convertDateForm,
} from "utils/utils";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";

const VendorReport = ({ vendor }) => {
  const [vendorInfo, setVendorInfo] = useState([]);
  const [stores, setStores] = useState("");
  const [storesDepartments, setStoresDepartments] = useState("");
  const [store, setStore] = useState("");
  const [menuloading, setMenuLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  const [department, setDepartment] = useState("");
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [selectedData, setSelectedData] = useState({});
  const [totalSalesNQt, setTotlaSalesNQt] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const getVendorsData = await DbManager.GET(
        `/achieve/vendorsStoreNDepart?vendor=${vendor}`
      );

      if (getVendorsData) {
        const storeNames = [
          ...new Set(getVendorsData.map((item) => item.store_name)),
        ];
        const departmentNames = [
          ...new Set(getVendorsData.map((item) => item.department_name)),
        ];

        setStores(storeNames);
        setStoresDepartments(departmentNames);
        setMenuLoading(false);
      } else {
        console.error("no data received");
      }
    };
    fetchData();
  }, [vendor]);

  const retriveDataBasedOnUserInfo = async (e) => {
    e.preventDefault();
    const convertedStartDate = convertDateForm(startDate);
    const convertedEndDate = convertDateForm(endDate);

    if (store === "" && department === "") {
      if (Object.keys(vendorInfo).length === 0) {
        userInputError("설정하신기간안에 선택된 아이템이없습니다");
      } else {
        userInputError(
          "아직입력되지 않은 입력값이 있습니다. 기입하지 않은 입력값을 넣어주세요"
        );
      }
    } else {
      const retrieveData = await DbManager.GET(
        `/achieve/vendors?store=${store}&department=${department}&startDate=${convertedStartDate}&endDate=${convertedEndDate}`
      );

      if (retrieveData) {
        const accumulateByItem = accumulateByItems(retrieveData);
        const entireAccumulatedTotal = cacluateTotals(accumulateByItem);

        setTotlaSalesNQt(entireAccumulatedTotal);
        setVendorInfo(Object.values(accumulateByItem));
        const selectData = {
          store: store,
          department: department,
          startDate: startDate,
          endDate: endDate,
        };

        setSelectedData(selectData);
        setLoading(false);
      }
    }
  };

  const navigate = useNavigate();
  const productList = () =>
    vendorInfo.map((item) => {
      return { prodName: item.categoryN, prodQauntity: item.totalQt };
    });

  const linkToOrderPage = (e) => {
    e.preventDefault();
    if (vendorInfo) console.log(vendorInfo);
    const filteredList = productList(vendorInfo);

    if (Object.keys(filteredList).length !== 0) {
      navigate("order", { state: filteredList });
    } else {
      userInputError("설정하신기간안에 선택된 아이템이없습니다");
    }
  };

  const userInputError = (text) =>
    toast.warn(`${text}`, {
      position: "bottom-center",
    });
  return (
    <Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            marginTop: "20px ",
          }}
        >
          {menuloading === true ? (
            <></>
          ) : (
            <>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <SelectButton
                    setData={setStore}
                    selected={store}
                    title={"Store"}
                    data={stores}
                  />
                  <SelectButton
                    setData={setDepartment}
                    selected={department}
                    title={"Department"}
                    data={storesDepartments}
                  />
                </Box>
                <DateSelector
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  endDate={endDate}
                  startDate={startDate}
                />
              </Box>
            </>
          )}

          <Box
            sx={{
              marginLeft: "15px",
              display: "flex",
              justifyContent: "space-evenly",
              width: "200px",
            }}
          >
            <Button
              onClick={retriveDataBasedOnUserInfo}
              variant="contained"
              color="success"
              sx={{
                backgroundColor: "#129d58",
                width: "6vw",
                borderRadius: "10px",
              }}
            >
              조회
            </Button>

            <ToastContainer />
            <Button
              onClick={linkToOrderPage}
              variant="contained"
              color="success"
              sx={{
                backgroundColor: "#129d58",
                width: "6vw",
                borderRadius: "10px",
                marginLeft: "10px",
              }}
            >
              주문
            </Button>
          </Box>
        </Box>
        <VendorReportTable
          selectedData={selectedData}
          totalSalesNQt={totalSalesNQt}
          vendorInfo={vendorInfo}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default VendorReport;
