import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { Box } from "@mui/material";
import { fontBold } from "styles/style";

import NumberCounter from "buttons/NumberCounter";
import { flexCenterStyles } from "styles/style";

const OrderProduct = (props) => {
  const { setCurrentOrderTable, currentOrderTable } = props;
  const [currentTotal, setCurrentTotal] = useState(0);

  useEffect(() => {
    if (Object.values(currentOrderTable).length !== 0) {
      const calculatedTotal = currentOrderTable.reduce((total, item) => {
        return total + (1 + item.tax1 + item.tax2) * item.price * item.Quantity;
      }, 0);
      setCurrentTotal(calculatedTotal);
    }
  }, [currentOrderTable, currentTotal]);

  const calculateTotal = ({ tax1, tax2, quantity, price }) => {
    return ((1 + tax1 + tax2) * quantity * price).toFixed(2);
  };

  return (
    <Box>
      <Box
        sx={{
          ...fontBold,
          textAlign: "center",
          fontSize: "30px",
          padding: "20px",
        }}
      >
        주문 페이지
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item</TableCell>
              <TableCell>Price</TableCell>

              <TableCell sx={{ textAlign: "center" }}>Quantity</TableCell>
              <TableCell>Sum</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(currentOrderTable).length === 0 ? (
              <></>
            ) : (
              currentOrderTable.map((row, index) => (
                <TableRow key={row.sub_c_name + index}>
                  <TableCell>{row.sub_c_name}</TableCell>
                  <TableCell>{row.price}</TableCell>

                  <TableCell sx={flexCenterStyles}>
                    <NumberCounter
                      currentNum={row.Quantity}
                      setCurrentOrderTable={setCurrentOrderTable}
                      currentOrderTable={currentOrderTable}
                      index={index}
                    ></NumberCounter>
                  </TableCell>

                  <TableCell>
                    {"$ " +
                      calculateTotal({
                        tax1: row.tax1,
                        tax2: row.tax2,
                        quantity: row.Quantity,
                        price: row.price,
                      })}
                  </TableCell>
                </TableRow>
              ))
            )}

            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell sx={fontBold}>
                Total ${currentTotal.toFixed(2)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OrderProduct;
