import { Box } from "@mui/material";
import "./App.css";
import Authenticated from "./layouts/authenticated/Authenticated";
import { Routes, Route, BrowserRouter } from "react-router-dom";

function App() {
  return (
    <Box className="App" sx={{}}>
      <Box
        sx={{
          "@media (max-width:600px)": {
            flexDirection: "column",
          },
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<Authenticated />} />
          </Routes>
        </BrowserRouter>
      </Box>
    </Box>
  );
}

export default App;
