import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const ExportPDF = (data) => {
  return html2canvas(data).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "letter");
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    // pdf.save("chart.pdf");
    return pdf.output("blob");
  });
};
