export const branchList = {
  hcq: "Coquitlam",
  hdn: "Downtown",
  hll: "Langley",
  hrm: "Richmond",
  hcc: "Coquitlam Center",
  hpc: "Port Coquitlam",
  hdb: "Dunbar",
  eub: "UBC",
};
